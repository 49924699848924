<template>
	<Layout>
		<PageHeader :title="title" :items="items" />
		<div class="col-lg-12">
			<!-- Pickers for cards -->
			<div class="row">
				<!-- Select Branch -->
				<div class="col-md-4 col-6 mb-3">
					<div class="form-group">
						<label for="role">Select A Branch</label>
						<select class="form-control" name="role" id="role" v-model="selectedCardBranch">
							<template v-for="(branch, index) in branches">
								<option :key="index" :value="branch._id">
									{{ branch.name }}
								</option>
							</template>
						</select>
					</div>
				</div>
				<div class="col-md-4 col-6">
					<!-----------Net Profit Line Graph Monthly filter------------->
					<div class="form-group">
						<label for="branch">Filter By Month</label>
						<date-picker
							value-type="YYYY-MM"
							format="YYYY-MM"
							v-model="selectedCardMonth"
							type="month"
							lang="en"
							placeholder="Select Month"
						/>
					</div>
				</div>

				<!----------- Weekly Filter------------->
				<div class="col-md-4 col-6">
					<div class="form-group">
						<label for="branch">Filter By Week</label>
						<date-picker
							value-type="YYYY-MM-DD"
							format="w"
							v-model="selectedCardWeek"
							type="week"
							lang="en"
							placeholder="Select Week"
						/>
					</div>
				</div>
			</div>

			<!-----------Float Cards------------->

			<div class="row">
				<template v-for="(widget, k) in widgetData">
					<div v-if="widget.visible" :key="widget.text" class="col-xl-4 col-sm-4 col-md-4 col-12">
						<div class="widget-simple text-center card">
							<div class="card-body">
								<h3 v-if="k == 'totalFloat'" class="text-success counter mt-0">
									{{ formatMoney(widget.number - iotSumComputed) }}
								</h3>
								<h3 v-else class="text-success counter mt-0">{{ widget.number }}</h3>
								<p class="text-muted mb-0">{{ widget.text }}</p>
							</div>
						</div>
					</div>
				</template>
			</div>
			<div class="row">
				<!-- Select Branch -->
				<div class="col-md-4 col-6 mb-3">
					<div class="form-group">
						<label for="role">Select A Branch</label>
						<select class="form-control" name="role" id="role" v-model="selectedBranch">
							<template v-for="(branch, index) in branches">
								<option :key="index" :value="branch._id">
									{{ branch.name }}
								</option>
							</template>
						</select>
					</div>
				</div>
				<div class="col-md-4 col-6">
					<!-----------Net Profit Line Graph Monthly filter------------->
					<div class="form-group">
						<label for="branch">Select Year</label>
						<date-picker
							value-type="YYYY-MM-DD"
							format="YYYY"
							v-model="netProfitFilters.monthly"
							type="year"
							lang="en"
							placeholder="Select Year"
						/>
					</div>
				</div>

				<!-----------Net Profit Line Graph Weekly Filter------------->
				<div class="col-md-4 col-6">
					<div class="form-group">
						<label for="branch">Select Month</label>
						<date-picker
							value-type="YYYY-MM-DD"
							format="MMMM YYYY"
							v-model="netProfitFilters.weekly"
							type="month"
							lang="en"
							placeholder="Select Month"
						/>
					</div>
				</div>
			</div>
			<div class="card">
				<div class="card-body">
					<h4 class="header-title">
						Net Profit for
						{{ currentGraphBranch ? currentGraphBranch.name : "" }}
					</h4>
					<p class="sub-header">Net profit for the year</p>
					<LineChart ref="netProfitLineChart" />
				</div>
			</div>
		</div>

		<!----------- Commission Payout Line Graph------------->
		<div class="row">
			<div class="col-lg-6">
				<div class="row">
					<div class="col-md-6">
						<!----------- Commission Payout Line Graph Monthly Filter------------->
						<div class="form-group">
							<label for="branch">Select A Year</label>
							<date-picker
								value-type="YYYY-MM-DD"
								format="YYYY"
								v-model="commissionFilters.monthly"
								type="year"
								lang="en"
								placeholder="Select A Year"
							/>
						</div>
					</div>

					<!----------- Commission Payout Line Graph Weekly Filter------------->
					<div class="col-md-6">
						<div class="form-group">
							<label for="branch">Select A Month</label>
							<date-picker
								value-type="YYYY-MM-DD"
								format="MMMM YYYY"
								v-model="commissionFilters.weekly"
								type="month"
								lang="en"
								placeholder="Select A Month"
							/>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h4 class="header-title">
							Commission Payout for
							{{ currentGraphBranch ? currentGraphBranch.name : "" }}
						</h4>
						<p class="sub-header">All the commission that was paid out to employees</p>
						<LineChart ref="commissionLineChart" />
					</div>
				</div>
			</div>
			<!-- end col -->

			<!-----------Money In vs Money Out Line Graph------------->
			<div class="col-lg-6">
				<div class="row">
					<div class="col-md-6">
						<!-----------Money In vs Money Out Line Graph Monthly filter------------->
						<div class="form-group">
							<label for="branch">Select A Year</label>
							<date-picker
								value-type="YYYY-MM-DD"
								format="YYYY"
								v-model="moneyFilters.monthly"
								type="year"
								lang="en"
								placeholder="Select A Year"
							/>
						</div>
					</div>

					<!-----------Money In vs Money Out Line Graph Weekly Filter------------->
					<div class="col-md-6">
						<div class="form-group">
							<label for="branch">Select A Month</label>
							<date-picker
								value-type="YYYY-MM-DD"
								format="MMMM YYYY"
								v-model="moneyFilters.weekly"
								type="month"
								lang="en"
								placeholder="Select A Month"
							/>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<h4 class="header-title">
							Money In vs Money Out for
							{{ currentGraphBranch ? currentGraphBranch.name : "" }}
						</h4>
						<p class="sub-header">
							The relationship between money in and money out over the filtered time period
						</p>
						<LineChart ref="moneyLineChart" />
					</div>
				</div>
			</div>
			<!-- end col -->
		</div>

		<!----------- Machines on location Table------------->
		<div class="row">
			<div class="col-lg-12">
				<b-card>
					<h4 class="header-title mb-3">
						Machines on Location for
						{{ currentGraphBranch ? currentGraphBranch.name : "" }}
					</h4>
					<b-input placeholder="Search here..." v-model="search" />
					<br />
					<b-table
						thead-class="red-t-head"
						:filter="search"
						outlined
						:per-page="perPage"
						:current-page="currentPage"
						hover
						:fields="machinesTransactionsHeaders"
						:items="machineTransactionItems"
					></b-table>
					<b-pagination
						:total-rows="machineTransactionItems && machineTransactionItems.length"
						:per-page="perPage"
						v-model="currentPage"
					></b-pagination>
				</b-card>
			</div>
		</div>
	</Layout>
</template>

<script>
	import Layout from "../../../layouts/vertical.vue";
	import PageHeader from "@/components/Page-header.vue";
	import Table from "@/components/tables/BasicTable.vue";
	import { mapActions, mapState, mapGetters } from "vuex";
	import BarChart from "@/components/charts/CustomBarChart.vue";
	import LineChart from "@/components/charts/customLineChart.vue";
	import DatePicker from "vue2-datepicker";
	import dayjs from "dayjs";

	/**
	 * Starter component
	 */
	export default {
		// Set window title
		page: {
			title: "Branch Reports",
		},
		components: {
			Layout,
			PageHeader,
			Table,
			LineChart,
			BarChart,
			DatePicker,
		},

		async mounted() {
			await this.getBranches();
			await this.getGameboxes();
			await this.getTransactions();
			//Set selectedBranch & selectedCardBranch to first branch in the list
			this.selectedBranch = this.branches[0]?._id;
			this.selectedCardBranch = this.branches[0]?._id;

			this.netProfitFilters.monthly = dayjs().format("YYYY-MM-DD");
			this.commissionFilters.monthly = dayjs().format("YYYY-MM-DD");
			this.moneyFilters.monthly = dayjs().format("YYYY-MM-DD");

			this.selectedCardWeek = dayjs().format("YYYY-MM-DD");

			await this.getIOTForWeek();
			this.pollingInterval = setInterval(async () => {
				await this.loadMachineTableData();
				await this.getIOTForWeek();
			}, 2000);
		},
		destroyed() {
			clearInterval(this.pollingInterval);
		},

		computed: {
			...mapState("branchModule", ["branches"]),
			...mapState("gameboxModule", ["gameboxes"]),
			...mapState("transactionsModule", ["transactions"]),
			...mapGetters("gameboxModule", ["getGameboxByBranch"]),
			...mapGetters("transactionsModule", ["getTransactionsByBranch"]),
			currentBranch() {
				return this.branches.find((b) => b._id === this.selectedCardBranch);
			},
			currentGraphBranch() {
				return this.branches.find((b) => b._id === this.selectedBranch);
			},
			iotSumComputed() {
				return this.iotSum;
			},
		},

		data() {
			return {
				iotSum: 0,
				pollingInterval: null,
				title: "Branch Report",
				items: [
					{
						text: "Reports",
					},
					{
						text: "Branch Report",
						active: true,
					},
				],
				selectedCardBranch: "",
				selectedCardMonth: "",
				selectedCardWeek: "",
				widgetData: {
					baseFloat: {
						number: "0",
						text: "Location's Base Float",
						chartColor: "#1abc9c",
						visible: true,
					},
					additionalFloat: {
						number: "0",
						text: "Additional Float For Selected Week",
						chartColor: "#1abc9c",
						visible: true,
					},
					totalFloat: {
						number: "0",
						text: "Total Float For Month",
						chartColor: "#1abc9c",
						visible: true,
					},
				},
				selectedBranch: "",
				commissionHeaders: [
					{
						text: "Week #",
						value: "week",
					},
					{
						text: "Amount Paid",
						value: "commission",
						type: "money",
					},
				],
				moneyHeaders: [
					{
						text: "Week",
						value: "week",
					},
					{
						text: "Money In",
						value: "totalIn",
						type: "money",
					},
					{
						text: "Money Out",
						value: "totalOut",
						type: "money",
					},
				],
				//Machine on location
				currentPage: 1,
				perPage: 5,
				search: "",
				machinesTransactionsHeaders: [
					{
						label: "Serial Number",
						key: "serialNumber",
						sortable: true,
					},
					{
						label: "Name",
						key: "name",
						sortable: true,
					},
					{
						label: "Reading In",
						key: "readingIn",
						sortable: true,
					},
					{
						label: "Reading Out",
						key: "readingOut",
						sortable: true,
					},
					{
						label: "Game",
						key: "game",
						sortable: true,
					},
				],
				machineTransactionItems: [],

				moneyFilters: {
					monthly: "",
					weekly: "",
				},
				commissionFilters: {
					monthly: "",
					weekly: "",
				},
				netProfitFilters: {
					monthly: "",
					weekly: "",
				},
			};
		},

		methods: {
			...mapActions("branchModule", ["getBranches"]),
			...mapActions("gameboxModule", ["getGameboxes"]),
			...mapActions("transactionsModule", [
				"getTransactions",
				"getTransactionSummary",
				"getMonthOrWeekTransaction",
				"getFloatReinjection",
			]),
			/**
			 * Method used to convert money value to currency string
			 */
			formatMoney(amount) {
				return new Intl.NumberFormat("en-US", {
					style: "currency",
					currency: "USD",
				}).format(amount);
			},
			// Method used to get IOt transactions for the week
			async getIOTForWeek() {
				try {
					let completed = !dayjs(this.selectedCardWeek).isSame(dayjs(), "weeks");
					if (!!completed) return (this.iotSum = 0);
					const { data } = await this.getTransactions({
						branchId: this.selectedCardBranch,
						startDate: dayjs(this.selectedCardWeek || undefined)
							.startOf("w")
							.format("YYYY-MM-DD"),
						endDate: dayjs(this.selectedCardWeek).endOf("w").format("YYYY-MM-DD"),
						type: "IOT-ENTRY",
						completed: completed,
					});
					this.iotSum = data.results.reduce((p, a) => p + a.totalOut, 0);
				} catch (error) {
					console.log(error);
				}
			},
			/**
			 * Method used to get the float for the current branch for the current week/month
			 */
			async getFloatForCurrentPeriod(period = "week") {
				try {
					const { results } = await this.getFloatReinjection({
						branchId: this.selectedCardBranch,
						startDate: dayjs().startOf(period).format("YYYY-MM-DD"),
						endDate: dayjs().endOf(period).format("YYYY-MM-DD"),
					});
					if (results && results.length > 0) {
						// Get transaction with the selected branch
						const tData = results.find((t) => t.branch?._id === this.selectedBranch);
						return tData?.additionalFloat || 0;
					}
					return null;
				} catch (error) {
					console.log(error);
				}
			},
			/**
			 * Get float based on seletec month
			 */
			async getFloatForSelectedMonth() {
				try {
					// check if dates are set before running function
					if (!this.selectedCardBranch || !this.selectedCardMonth) return;
					const { results } = await this.getFloatReinjection({
						branchId: this.selectedCardBranch,
						startDate: dayjs(this.selectedCardMonth).startOf("month").format("YYYY-MM-DD"),
						endDate: dayjs(this.selectedCardMonth).endOf("month").format("YYYY-MM-DD"),
					});

					if (results && results.length > 0) {
						// Get transaction with the selected branch
						return results;
					}
					return null;
				} catch (error) {
					console.log(error);
				}
			},
			/**
			 * Get float based on seletec month
			 */
			async getFloatForSelectedWeek() {
				try {
					// check if dates are set before running function
					if (!this.selectedCardBranch || !this.selectedCardWeek) return;
					const { results } = await this.getFloatReinjection({
						branchId: this.selectedCardBranch,
						startDate: dayjs(this.selectedCardWeek).startOf("week").format("YYYY-MM-DD"),
						endDate: dayjs(this.selectedCardWeek).endOf("week").format("YYYY-MM-DD"),
					});

					if (results && results.length > 0) {
						return results;
					}
					return null;
				} catch (error) {
					console.log(error);
				}
			},
			async loadMachineTableData() {
				// Get all boxes that belong to the selected branch
				const branchMachines = await this.gameboxes.filter(
					(b) => b.branch?._id === this.selectedBranch
				);
				// If there are no machines, do nothing
				if (branchMachines.length == 0) return;

				// Get all transactions for the selected branch
				const { data } = await this.getTransactions({
					branchId: this.selectedBranch,
					readingType: "REGULAR",
				});
				// Sort the transactions by transaction date
				let results = data.results.sort((a, b) => {
					return dayjs(a.transactionDate).isAfter(dayjs(b.transactionDate)) ? -1 : 1;
				});
				// Reset table data
				this.machineTransactionItems = [];

				//Loop over each machine/box
				for (let i = 0; i < branchMachines.length; i++) {
					let lastTransaction = {
						readingIn: 0,
						readingOut: 0,
					};
					//Find the first transaction for the machine in the results array
					const firstTransaction = results.find((t) => t.gamebox?._id === branchMachines[i]._id);
					// Set the rreading in & out values
					lastTransaction.readingIn = firstTransaction?.readingIn || 0;
					lastTransaction.readingOut = firstTransaction?.readingOut || 0;
					// Add them to the table array
					this.machineTransactionItems.push({
						serialNumber: branchMachines[i].serialNumber,
						readingIn: lastTransaction.readingIn,
						readingOut: lastTransaction.readingOut,
						name: branchMachines[i].name,
						game: branchMachines[i].games[0].name,
					});
				}
			},

			async loadCommissionLineChart() {
				let commissionsList = [];

				const filterDate = this.commissionFilters.weekly || this.commissionFilters.monthly;
				const filterPeriod = this.commissionFilters.weekly ? "weekly" : "monthly";
				const { startDate, endDate, period } = await this.getFilterDates(filterDate, filterPeriod);
				const branchTransactionSummary = await this.getMonthOrWeekTransaction({
					branchId: this.selectedBranch,
					period,
					startDate,
					endDate,
				});

				if (branchTransactionSummary.status == 200) {
					if (Array.isArray(branchTransactionSummary.data)) {
						const { data } = branchTransactionSummary;

						if (period == "weekly") {
							for (let i = 0; i < data.length; i++) {
								let summary = data[i];
								commissionsList[summary._id] = summary.commission;
							}
							commissionsList.splice(0, commissionsList.length - 4);
						} else {
							for (let i = 0; i < data.length; i++) {
								let summary = data[i];
								let date = (summary._id + "-01").split("-");
								let dateTime = Date.UTC.apply(null, date);
								let month = new Date(dateTime).getUTCMonth();
								commissionsList[month - 1] = summary.commission;
							}
						}

						for (let i = 0; i < commissionsList.length; i++) {
							if (commissionsList?.[i] == undefined) {
								commissionsList[i] = 0;
							}
						}
					}

					const commissions = {
						label: "Commission",
						color: "#118C4F",
						data: commissionsList,
					};

					this.$refs["commissionLineChart"].render(this.getChartLabel(filterPeriod), [commissions]);
				}
			},

			async loadNetProfitsLineChart() {
				//List of net profit data points to be used in graph
				let netProfitList = [];

				//date selected to get filtered
				const filterDate = this.netProfitFilters.weekly || this.netProfitFilters.monthly;
				const filterPeriod = this.netProfitFilters.weekly ? "weekly" : "monthly";
				const { startDate, endDate, period } = await this.getFilterDates(filterDate, filterPeriod);

				//Summary of transactions that took place for the filtered period at the selected branch
				const branchTransactionSummary = await this.getMonthOrWeekTransaction({
					branchId: this.selectedBranch,
					period,
					startDate,
					endDate,
				});

				if (branchTransactionSummary.status == 200) {
					if (Array.isArray(branchTransactionSummary.data)) {
						const { data } = branchTransactionSummary;
						if (filterPeriod == "weekly") {
							//each week for the year is used as the index for data values
							for (let i = 0; i < data.length; i++) {
								let summary = data[i];
								netProfitList[summary._id] = summary.profits;
							}
							//remove the leading values and saves the last 4 values representing the 4 weeks of the month
							netProfitList.splice(0, netProfitList.length - 4);
						} else {
							for (let i = 0; i < data.length; i++) {
								let summary = data[i];

								//split date into array to get month value
								let date = (summary._id + "-01").split("-");
								let dateTime = Date.UTC.apply(null, date);
								let month = new Date(dateTime).getUTCMonth();

								//use month of the year as index for data points
								netProfitList[month - 1] = summary.profits;
							}
						}

						for (let i = 0; i < netProfitList.length; i++) {
							if (netProfitList?.[i] == undefined) {
								netProfitList[i] = 0;
							}
						}
					}

					const netProfit = {
						label: "Net Profit",
						color: "#118C4F",
						data: netProfitList,
					};

					this.$refs["netProfitLineChart"].render(this.getChartLabel(filterPeriod), [netProfit]);
				}
			},

			//Loads data for money line chart
			async loadMoneyLineChart() {
				//List of money In and money Out values for line graph data
				let moneyInList = [];
				let moneyOutList = [];

				//finds the date to filter by
				const filterDate = this.moneyFilters.weekly || this.moneyFilters.monthly;

				// finds how to summerize the filtered data whether weekly or monthly
				const filterPeriod = this.moneyFilters.weekly ? "weekly" : "monthly";

				const { startDate, endDate, period } = await this.getFilterDates(filterDate, filterPeriod);

				//Summary of the transactions that took place at the selected branch over the filtered period
				const branchTransactionSummary = await this.getMonthOrWeekTransaction({
					branchId: this.selectedBranch,
					period,
					startDate,
					endDate,
				});

				if (branchTransactionSummary.status == 200) {
					if (Array.isArray(branchTransactionSummary.data)) {
						const { data } = branchTransactionSummary;

						if (period == "weekly") {
							//loads data into their respective list arrays
							//each week of the year is used as the index to store that weeks value
							for (let i = 0; i < data.length; i++) {
								let summary = data[i];
								moneyInList[summary._id] = summary.totalIn;
								moneyOutList[summary._id] = summary.totalOut;
							}

							//removes all the leading empty indexes and leaves the last 4 representing the 4 weeks of a month
							moneyInList.splice(0, moneyInList.length - 4);
							moneyOutList.splice(0, moneyOutList.length - 4);
						} else {
							//loads data into their respective list arrays
							//each month of the year is used as the index to store that months value
							for (let i = 0; i < data.length; i++) {
								let summary = data[i];

								//split date into array to get month index
								let date = (summary._id + "-01").split("-");
								let dateTime = Date.UTC.apply(null, date);
								let month = new Date(dateTime).getUTCMonth();

								//month value is used as the index to store that months transaction
								moneyInList[month - 1] = summary.totalIn;
								moneyOutList[month - 1] = summary.totalOut;
							}
						}

						//finds all the index that have no values because there was no transactions on that month
						//then replacing it 0
						for (let i = 0; i < moneyInList.length; i++) {
							if (moneyInList?.[i] == undefined) {
								moneyInList[i] = 0;
							}
						}
						for (let i = 0; i < moneyOutList.length; i++) {
							if (moneyOutList?.[i] == undefined) {
								moneyOutList[i] = 0;
							}
						}
					}

					//Line graph line indormation
					const moneyIn = {
						label: "Money In", //Label of line
						color: "#118C4F", // color of line
						data: moneyInList, // data points
					};
					const moneyOut = {
						label: "Money Out", //Label of line
						color: "#FF2400", // color of line
						data: moneyOutList, // data points
					};

					this.$refs["moneyLineChart"].render(this.getChartLabel(filterPeriod), [
						moneyOut,
						moneyIn,
					]);
				}
			},

			//Finds the x-axis labels for line graphs
			getChartLabel(filterPeriod) {
				if (filterPeriod == "monthly")
					return [
						"Jan",
						"Feb",
						"Mar",
						"Apr",
						"May",
						"Jun",
						"Jul",
						"Aug",
						"Sep",
						"Oct",
						"Nov",
						"Dec",
					];
				else if (filterPeriod == "weekly") return ["Week #1", "Week #2", "Week #3", "Week #4"];
				else return [];
			},

			//Finds the start and end date that will set the range to filter data from api
			getFilterDates(filterDate, filterPeriod) {
				let startDate = "";
				let endDate = "";
				let period = "";

				if (filterDate != "" && filterPeriod == "weekly") {
					period = "weekly";
					startDate = dayjs(filterDate).startOf("month").format("YYYY-MM-DD");
					endDate = dayjs(filterDate).endOf("month").format("YYYY-MM-DD");
				} else if (filterDate != "" && filterPeriod == "monthly") {
					period = "monthly";
					startDate = dayjs(filterDate).startOf("year").format("YYYY-MM-DD");
					endDate = dayjs(filterDate).endOf("year").format("YYYY-MM-DD");
				}
				return { startDate, endDate, period };
			},
		},

		watch: {
			async selectedCardMonth(newValue, oldValue) {
				if (newValue != oldValue) {
					//make 2 widget cards invisible
					this.widgetData.baseFloat.visible = false;
					this.widgetData.additionalFloat.visible = false;

					const data = await this.getFloatForSelectedMonth();
					let additionalFloat = 0;
					if (data) {
						// reduce additional float from results
						additionalFloat = data.reduce((acc, curr) => acc + curr.additionalFloat, 0);
					}
					//set month float widget data
					this.widgetData.totalFloat.number = additionalFloat;
					this.widgetData.totalFloat.text = "Total Additional Float For Month";
				}
			},
			async selectedCardWeek(newValue, oldValue) {
				if (newValue != oldValue) {
					//make all widget cards visible
					this.widgetData.baseFloat.visible = true;
					this.widgetData.additionalFloat.visible = true;
					this.widgetData.totalFloat.visible = true;

					// reset selectedCardMonth
					this.selectedCardMonth = "";

					const data = await this.getFloatForSelectedWeek();
					let additionalFloat = 0;
					if (data) {
						// reduce additional float from results
						additionalFloat = data.reduce((acc, curr) => acc + curr.additionalFloat, 0);
					}
					//set month float widget data
					this.widgetData.additionalFloat.number = this.formatMoney(additionalFloat);
					// Set total float which is the base float plus additional float
					// get iot sum for the week
					await this.getIOTForWeek();
					this.widgetData.totalFloat.number = Number(
						this.currentBranch?.locationFloat + additionalFloat - Number(this.iotSum)
					);

					this.widgetData.totalFloat.text = "Total Float For Week";
				}
			},
			async selectedCardBranch(newValue, oldValue) {
				if (newValue != oldValue) {
					//make all widget cards visible
					this.widgetData.baseFloat.visible = true;
					this.widgetData.additionalFloat.visible = true;
					this.widgetData.totalFloat.visible = true;
					//Set selected month and week to null
					this.selectedCardMonth = dayjs().add(2, "month").format("YYYY-MM-DD");
					this.selectedCardWeek = dayjs().add(2, "month").format("YYYY-MM-DD");
					setTimeout(() => {
						this.selectedCardMonth = "";
						this.selectedCardWeek = dayjs().format("YYYY-MM-DD");
					}, 500);
					//Set branch base float when branch is changed
					this.widgetData.baseFloat.number = this.formatMoney(
						this.currentBranch?.locationFloat || 0
					);

					// Get additional float for the month for the branch
					let a = (await this.getFloatForCurrentPeriod("month")) || 0;
					// Get addtional float for branch selected
					let b = (await this.getFloatForCurrentPeriod("week")) || 0;
					this.widgetData.totalFloat.number = Number(b + this.currentBranch?.locationFloat || 0);
					this.widgetData.additionalFloat.number = this.formatMoney(b);
				}
			},
			selectedBranch: async function (newVal, oldVal) {
				if (newVal != oldVal) {
					this.loadMachineTableData();

					if (this.commissionFilters.monthly || this.commissionFilters.weekly)
						this.loadCommissionLineChart();

					if (this.moneyFilters.monthly || this.moneyFilters.weekly) this.loadMoneyLineChart();

					if (this.netProfitFilters.monthly || this.netProfitFilters.weekly)
						this.loadNetProfitsLineChart();
				}
			},

			"moneyFilters.weekly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.moneyFilters.monthly = "";
						this.loadMoneyLineChart();
					}
				}
			},

			"moneyFilters.monthly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.moneyFilters.weekly = "";
						this.loadMoneyLineChart();
					}
				}
			},

			"commissionFilters.weekly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.commissionFilters.monthly = "";
						this.loadCommissionLineChart();
					}
				}
			},

			"commissionFilters.monthly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.commissionFilters.weekly = "";
						this.loadCommissionLineChart();
					}
				}
			},

			"netProfitFilters.weekly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.netProfitFilters.monthly = "";
						this.loadNetProfitsLineChart();
					}
				}
			},

			"netProfitFilters.monthly": function (newVal, oldVal) {
				if (newVal != oldVal) {
					if (newVal != "") {
						this.netProfitFilters.weekly = "";
						this.loadNetProfitsLineChart();
					}
				}
			},
		},
	};
</script>
